import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import Categories from './Categories';
import CanProfiles from './CanProfiles';
import { Groups } from './Groups';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import PowerProfiles from '../PowerProfiles/PowerProfiles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Cookies from 'universal-cookie';
import './UnitFields.scss';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import ModbusProfiles from "./ModbusProfiles";

export const UnitFields = () => {
  const {
    unitInfo,
    setName,
    setReferenceNumber,
    totalKm,
    setTotalKm,
    setPreKm,
    kmInitialValue = 0,
    totalRun1,
    setTotalRun1,
    setPreRun1,
    run1InitialValue = 0,
    deviceTypeSupportsOffsets,
    note,
    setNote
  } = useDeviceContext();

  const { accessibility } = useAccessibilityContext()

  const cookies = new Cookies();

  const totalRun1Ref = useRef<HTMLInputElement>();
  const totalKmRef = useRef<HTMLInputElement>();
  const [useMiles, setUseMiles] = React.useState<Boolean>(
    cookies.get('useMiles') === 'true' || false
  );
  const [validationError, setValidationError] = React.useState({
    totalRun1: false,
    totalRun1Decimal: false,
    totalKmDecimal: false
  });

  const hoursToSeconds = (value: number) => {
    return value * 3600;
  }

  const secondsToHours = (value: number) => {
    return (value / 3600).toFixed(0);
  }

  const KM_TO_MILES_FACTOR: number = 1.60934;

  const onTotalRun1 = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (setTotalRun1 && validNumberInput(evt.target.value)) {
      if (!validIntegerInput(evt.target.value)) {
        clearFocus(totalRun1Ref);
        setValidationError({ ...validationError, totalRun1Decimal: true });
        return;
      }

      const plainValue = evt.target.value;
      if (plainValue === '') {
        setTotalRun1(undefined);
        setPreRun1(-1 * run1InitialValue);
      } else {
        const value = Number.parseInt(evt.target.value);
        const invalidTotalRun1 = value > 596523 || value < -596523;

        setValidationError({
          ...validationError,
          totalRun1Decimal: false,
          totalRun1: invalidTotalRun1
        });
        if (!invalidTotalRun1) {
          const newTotalRun1 = hoursToSeconds(value)
          setTotalRun1(newTotalRun1);
          setPreRun1(newTotalRun1 - run1InitialValue);
        }
      }
    }
  };

  const onTotalKm = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (setTotalKm && validNumberInput(evt.target.value)) {
      if (!validIntegerInput(evt.target.value)) {
        clearFocus(totalKmRef);
        setValidationError({ ...validationError, totalKmDecimal: true });
        return;
      }
      setValidationError({ ...validationError, totalKmDecimal: false });

      const plainValue = evt.target.value;
      if (plainValue === '') {
        setTotalKm(undefined);
        setPreKm(-1 * kmInitialValue);
      } else {
        const value = Number.parseInt(evt.target.value);
        const newTotalKm = useMiles ? value * KM_TO_MILES_FACTOR : value;

        setTotalKm(newTotalKm);
        setPreKm(newTotalKm - kmInitialValue);
      }
    }
  };

  const onChangeDistanceUnit = (evt: React.ChangeEvent<{}>, value: string) => {
    setUseMiles(value === 'miles');
    cookies.set('useMiles', value === 'miles');
  };

  const parseTotalKm = (value: number | undefined) => {
    if (value === undefined || Number.isNaN(value)) return '';

    var parsed: number = value;
    if (useMiles) {
      parsed = value / KM_TO_MILES_FACTOR;
    }

    return parsed.toFixed(0);
  };

  const validNumberInput = (input: string) => {
    return /^[0-9,.]*$/.test(input);
  };

  const validIntegerInput = (input: string) => {
    return /^[0-9]*$/.test(input);
  };

  const clearFocus = (
    ref: React.MutableRefObject<HTMLInputElement | undefined>
  ) => {
    if (ref && ref.current) {
      ref.current.blur();
    }
  };

  return (
    <>
      <TextField
        name="unit.name"
        label="Unit Name"
        fullWidth
        variant="outlined"
        value={unitInfo.name || ''}
        margin="normal"
        onChange={evt => {
          setName && setName(evt.target.value);
        }}
      />
      <TextField
        name="unit.referenceNumber"
        label="Reference number"
        fullWidth
        variant="outlined"
        value={unitInfo.referenceNumber || ''}
        margin="normal"
        onChange={evt => {
          setReferenceNumber && setReferenceNumber(evt.target.value);
        }}
      />
      { deviceTypeSupportsOffsets &&
        <TextField
          name="unit.totalRun1"
          label="Engine hours"
          placeholder="Type here the actual engine hours"
          fullWidth
          variant="outlined"
          margin="normal"
          helperText={
            (validationError.totalRun1 &&
              'Value must be between -596523 and 596523') ||
            (validationError.totalRun1Decimal && 'No decimals allowed')
          }
          value={totalRun1 ? secondsToHours(totalRun1) : ''}
          onChange={onTotalRun1}
          inputRef={totalRun1Ref}
        />
      }
      { deviceTypeSupportsOffsets &&
        <div className="totalKm">
          <TextField
            name="unit.totalKm"
            label={'Distance offset in ' + (useMiles ? 'miles' : 'km   ')}
            placeholder="Type here the actual distance offset"
            fullWidth
            variant="outlined"
            margin="normal"
            helperText={validationError.totalKmDecimal && 'No decimals allowed'}
            value={parseTotalKm(totalKm)}
            onChange={onTotalKm}
            inputRef={totalKmRef}
          />
          <RadioGroup
            aria-label="position"
            name="position"
            value={useMiles ? 'miles' : 'km'}
            onChange={onChangeDistanceUnit}
            row
          >
            <FormControlLabel
              value="km"
              control={<Radio color="primary" />}
              label="Km"
              labelPlacement="start"
            />
            <FormControlLabel
              value="miles"
              control={<Radio color="primary" />}
              label="Miles"
              labelPlacement="start"
            />
          </RadioGroup>
        </div>
      }
      { accessibility?.categories.visible && <Categories /> }
      <CanProfiles />
      <ModbusProfiles />
      { accessibility?.groups.visible && <Groups /> }
      { accessibility?.powerMode.visible && <PowerProfiles /> }
      { accessibility?.notes.visible && <TextField
        name="unit.note"
        label="Note"
        multiline
        fullWidth
        variant="outlined"
        margin="normal"
        maxRows="4"
        value={note || ''}
        onChange={evt => {
          setNote && setNote(evt.target.value);
        }}
      />}
    </>
  );
};
