import { getEnviroment } from '../../../../env';
import axios from 'axios';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AccessToken } from '@okta/okta-auth-js';

const assetEndpoint = getEnviroment().endpoints.asset;

interface AssetNote {
  value: string;
}

const updateNote = (accessToken: AccessToken | undefined,
                    assetId: string,
                    note: string) => {
  return axios
    .patch<AssetNote[]>(`${assetEndpoint}/note`,
      {
        identifier: {
          type: 'ASSET_ID',
          value: assetId
        },
        note: note
      },
      defaultAxiosOptions(accessToken)
    )
    .catch(() => {
      throw new Error(`An Unexpected error occurred while trying to update note for asset: ${assetId}`)
    });
};

export const AssetApi = {
  updateNote
}